import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { graphql } from 'gatsby';
import Observer from 'react-intersection-observer';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import { TimelineLite } from 'gsap';
import ClientTestimonial from '../components/ClientTestimonial';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Bluekey from '../../static/assets/blue_key.svg';
import '../sass/_clienttestimonials.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as globalAnimation from '../animations/globalAnimation';
import { animateBG } from '../animations/TeamBios';
import { animateLists, animateSliders } from '../animations/Clienttestimonials';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
}

class ClientTestimonials extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    animateBG(this.tl, '.bg-orange');
    animateLists(
      this.tl,
      '.svg-line__line_blue',
      '.svg_circle_blue',
      '.client-image',
      '#description',
      '#lists',
      0,
      40
    );
  }

  animateSecondHeading = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateHeading(tl, '.second-title', '.second-subtitle');
    }
  };

  animateSliderSection = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateSliders(tl, '.slider-section');
    }
  };

  render() {
    const {
      data: {
        markdownRemark: {
          frontmatter: {
            title,
            sectionOne: { subtitle, lists },
            imgOne,
            imgTwo,
            imgThree,
            sliderSection,
            sliderSection: { cards },
          },
        },
      },
    } = this.props;
    const settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      centerPadding: '390px',
      slidesToShow: 1,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 8500,
      dots: true,
      lazyLoad: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const mobileSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Client Testimonials" />
        <Container fluid="true">
          <Row>
            <Col xs={12} lg={12}>
              <div className="main_wrapper min-vh-100">
                <div className="wrapper">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                    <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                  </div>
                </div>

                <div className="wrapper pt-0">
                  <Row noGutters>
                    <Col xs={12} lg={{ span: 6 }} className="py-3">
                      <div className="position-relative d-none d-md-block">
                        <div className="d-flex justify-content-center">
                          <div className="client1 m-2 align-self-center client-image">
                            <Img className="img-fluid" fixed={imgOne.image.childImageSharp.fixed} />
                          </div>
                          <div className="client2 m-2 align-self-top client-image">
                            <Img className="img-fluid" fixed={imgTwo.image.childImageSharp.fixed} />
                          </div>
                        </div>
                        <div className="pl-5">
                          <div className="client3 text-center mx-auto client-image">
                            <Img
                              className="img-fluid"
                              fluid={imgThree.image.childImageSharp.fluid}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-block d-md-none">
                        <div>
                          <Img className="img-fluid" fluid={imgThree.image.childImageSharp.fluid} />
                        </div>
                      </div>
                      <div className="bg-orange client-wrapper d-none d-md-block" />
                    </Col>
                    <Col xs={12} lg={6}>
                      <div className=" py-lg-4 py-lg-0">
                        <div className="position-relative line line-horizontal pl-3">
                          <Bluekey />
                        </div>
                        <p style={{ fontWeight: 'bold' }} id="description" className="pl-3">
                          {subtitle}
                        </p>
                        <div id="lists" className="circle-dark-bullets">
                          <ul>
                            {lists.map((list, index) => (
                              <li key={String(index)} className="my-3">
                                {list}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <Row className="bg-grey">
                <Col xs={12}>
                  <Observer onChange={this.animateSecondHeading} triggerOnce>
                    <div className="wrapper pl-0 pr-0 pb-0">
                      <div className="page_heading_section text-center">
                        <h3 className="page_heading text-uppercase d-lg-block d-none second-title">
                          {sliderSection.title}
                        </h3>
                        <h4 className="text-uppercase page_heading_child second-subtitle">
                          {sliderSection.title}
                        </h4>
                      </div>
                    </div>
                  </Observer>
                  <Observer onChange={this.animateSliderSection} triggerOnce>
                    <div className="py-5 slider-section">
                      <div className="blurred_effect-left d-none d-lg-block" />
                      <div className="blurred_effect-right d-none d-lg-block" />
                      <Slider {...settings} className="d-none d-lg-block">
                        {cards.map((card, index) => (
                          <ClientTestimonial
                            key={String(index)}
                            content={card.content}
                            post={card.post}
                            author={card.author}
                            image={card.image}
                          />
                        ))}
                      </Slider>
                      <Slider {...mobileSettings} className="d-lg-none">
                        {cards.map((card, index) => (
                          <ClientTestimonial
                            key={String(index)}
                            content={card.content}
                            post={card.post}
                            author={card.author}
                            image={card.image}
                          />
                        ))}
                      </Slider>
                    </div>
                  </Observer>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default ClientTestimonials;

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "client-testimonials" } }) {
      frontmatter {
        title
        sectionOne {
          subtitle
          lists
        }
        imgOne {
          image {
            childImageSharp {
              fixed(width: 195, height: 260, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        imgTwo {
          image {
            childImageSharp {
              fixed(width: 305, height: 305, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        imgThree {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sliderSection {
          title
          cards {
            content
            post
            author
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
