import React, { PureComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import Img from 'gatsby-image';
import './_clienttestimonial.scss';

export default class ClientTestimonial extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { content, post, author, image } = this.props;
    return (
      <Row>
        <Col className="testimonial-slider d-flex justify-conte my-5">
          <div className="testimonial-card p-3 p-md-5 mx-auto">
            <div>
              <Img className="slider_logo" fluid={image.childImageSharp.fluid} />
            </div>
            <div className="text-center">
              <p>{content}</p>
            </div>
            <div className="text-right">
              <h6 className="mb-0 text-bold text-lightblue">{post}</h6>
              <small className="text-uppercase text-muted">{author}</small>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
