import { Power1, Power2 } from 'gsap';

export function animateLists(tl, svgLine, svgCircle, image, description, lists, X, Y) {
  const o = 1.3;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFromTo('.description_column', 0.3, { opacity: 0 }, { opacity: 1 }, 0.3)
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      description,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      lists,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      image,
      1.3,
      {
        x: X,
        y: Y,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    );
}

export function animateSliders(tl, slider) {
  const o = 0.6;
  return tl.staggerFrom(
    slider,
    o,
    {
      opacity: 0,
      scale: 0.9,
      ease: Power1.easeOut,
    },
    0,
    o
  );
}
